import "./style.css"
import { Col, Container, Row } from 'react-bootstrap'
import AboutSection from "../../assets/AboutSection.png"
import Banner from '../../components/banner/Banner'
import NavBar from '../../components/Navbar'
import Portfolio from "../../components/portfolio/Portfolio"
import Contact from "../../components/contact/Contact"
import Faq from "../../components/faq/Faq"
import Footer from "../../components/footer/Footer"
import ThemeButton from "../../components/reuseable/ThemeButton"
import ThemeButtonLight from "../../components/reuseable/ThemeButtonLight"
import serviceCoverDesign from "../../assets/service-coverDesign.png"
import { useNavigate } from "react-router-dom"
import aboustusmock from "../../assets/aboustusmock.png"

const AboutUs = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/form")
    }

    const para = (
        <>
            Uno’s publishing arm, Uno Publishing Company, was established decade ago and has since grown substantially. <br /><br />
            We have a team of award-winning book writers, publishers, and marketers who turn ideas into best-selling books. <br /><br />
            We are known for creating groundbreaking new voices in writing and for publishing books that have shaped the way we live. <br /><br />
        </>
    )
    const subHeading = (
        <>
            Welcome To Uno Publishing
        </>
    )
    return (
        <>
            <div id='banner'>
                <NavBar />
                <Banner subHeading={subHeading} paragraph={para} image={aboustusmock} />
            </div>
            <div>
                <Container className='overflow-hidden'>
                    <Row className='justify-content-evenly'>
                        <Col className='mt-5 pt-5 ps-2' lg={5} >
                            <img src={AboutSection} alt="" style={{ width: "99%" ,marginTop:'30px' }} />
                        </Col>
                        <Col className="mt-0 pt-5" lg={6}>
                            <div id='aboutContent'>
                                <h1 className='mt-3' style={{ color: "#F8B830" }}>What Makes Us Your Perfect Self-Publishing Partner?</h1>
                                <p className='pt-3'>	<b>We Embrace Challenges:</b> At Uno, we thrive on pushing boundaries. If you have an unprecedented request or a unique vision for your book, we say yes! We make the impossible happen because we're dedicated to bringing your dreams to life. Count on us to be your unwavering partner throughout the journey, creating pure magic together.</p>
                                <p><b>	We Create Trust Before Creating Books:</b> We understand the immense trust you place in us when you hand us your manuscript. Respecting this trust is our top priority as a manuscript is something very personal and close to its writer’s heart. Every action we take is focused on making your story shine and surpassing your expectations. Each book we work on receives our undivided attention because we recognize its significance to you.</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className='overflow-hidden mb-5'>
                    <Row className='justify-content-evenly'>
                        <Col className="mt-5 pt-5" lg={6}>
                            <div id='aboutContent' className='mt-5'>
                                <h3>What Makes Us Your Perfect Self-Publishing Partner?</h3>
                                <p className='mt-5 mb-4'><b>	Pursuit of Perfection:</b> Excellence is embedded in our DNA. Our team consists of industry experts with a profound passion for publishing. We maintain meticulous quality control, involving no less than eight individuals who invest their time, effort, and expertise into every book we produce.
                                </p>
                                <p><b>Adaptive and Informed:</b> In an ever-evolving publishing landscape, we stay ahead of the curve. We embrace change, remaining agile and up to date with the latest trends. Leveraging our extensive knowledge and experience, we bring invaluable insights to every project we undertake and never shy away from unlearning or relearning</p>
                                <ThemeButton name="Start Project" click={handleClick} />
                            </div>
                        </Col>
                        <Col id='books' className=' pt-5 ps-5' lg={5} >
                            <img className="" src={serviceCoverDesign} alt="" style={{ width: "103%",marginTop:'45px'  }} />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <Portfolio />
                {/* <div className='mt-5'>
                    <h2 className='text-center'>Book a Call with Our Consultant to Get Help with Your Book</h2>
                    <p className='text-center'>Call Us to Kick-start Your Project Right Away!</p>
                    <div className='text-center '>
                        <ThemeButton name="Start project" click={handleClick} />
                        <span className="mx-2">
                            <a href="tel:(737) 910 3898">
                                <ThemeButtonLight name="Call Now" />
                            </a>
                        </span>
                        <a href="javascript:void(Tawk_API.toggle())"><ThemeButton name="Live Chat" /></a>
                    </div> */}
                {/* </div> */}
                <Contact />
                <Faq />
                <Footer />
            </div>
        </>
    )
}

export default AboutUs