import React from 'react'
import NavBar from '../../components/Navbar';
import Banner from '../../components/banner/Banner';
import StartProject from '../../components/startProject/StartProject';
import ServicesIcon from '../../components/servicesIcon/ServicesIcon';
import Portfolio from '../../components/portfolio/Portfolio';
import Services from '../../components/services/Services';
import Leading from '../../components/leading/Leading';
import Contact from '../../components/contact/Contact';
import Faq from '../../components/faq/Faq';
import Footer from '../../components/footer/Footer';
import mockup1 from "../../assets/mockup1Bg.png"


const AmazonBookPublishing = () => {
  const heading2 = (
    <>
       Self Publishing On Amazon 
    </>
)


  const heading = (
    <>
       Get Your<b> Book Published</b> On <b>Amazon</b>! 
    </>
)
  return (
    <>
    <div id='banner'>
        <NavBar />
        <Banner heading={heading} paragraph="" image={mockup1}/>
    </div>
    <StartProject heading1={heading2} />
    <ServicesIcon />
    <Portfolio />
    <div className='mt-4'>
        <Services />
    </div>
    <Leading />
    <Contact />
    <Faq />
    <Footer />
</>
  )
}

export default AmazonBookPublishing
