import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ThemeButton from '../reuseable/ThemeButton'
import mockup123 from "../../assets/mockup123.png"
import ThemeButtonLight from '../reuseable/ThemeButtonLight'
import "./style.css"
import { ZendeskAPI } from '../../ZendeskConfig'
import { useNavigate } from 'react-router-dom'

const StartProject = ({heading1}) => {
   
    const submit3 = () => {
        ZendeskAPI("messenger", "open");
    };

    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/form")
    }
    return (
        <div>
            <Container className='overflow-hidden'>
                <Row className='justify-content-evenly'>
                    <Col lg={6}>
                        <div className="text-center">
                            <img src={mockup123} alt="" id='bookImg' />
                        </div>
                    </Col>
                    <Col className=" mb-5" lg={5}>
                        <div id='startProjectContent' className='pt-5'>
                            <h2 className=' mt-5 pt-5' id='selfPublishingHeading'>
                            {heading1}</h2>
                            <p className='pt-1'>You've poured your heart and soul into your manuscript, but navigating the world of self-publishing can be overwhelming. At Uno Publishing, we understand the challenges you face, and we're here to make the process seamless for you. Our dedicated team is ready to transform your manuscript into a published book, so you can focus on what you do best – writing. 
                            </p> <p>The best part? With our services, you retain 100% of your net royalties! Let us help you publish your book, because your story deserves to be heard.
                            </p>
                           
                            {/* <button type="button" className="btn btn-warning px-5 py-2 mb-5 ">Start Project</button> */}
                            <a href="#contactDiv">
                                <ThemeButton name="Start project" click={handleClick} />
                            </a>
                            <span className="mx-2" id="centerBtnMediaQuery">
                                <a href="tel:(737) 209 7255">
                                    <ThemeButtonLight name="Call Now" />
                                </a>
                            </span>
                            <a href="javascript:void(Tawk_API.toggle())"><ThemeButton name="Live Chat" /></a>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default StartProject