import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Banner from '../../../components/banner/Banner'
import Contact from '../../../components/contact/Contact'
import Faq from '../../../components/faq/Faq'
import Footer from '../../../components/footer/Footer'
import NavBar from '../../../components/Navbar'
import Portfolio from '../../../components/portfolio/Portfolio'
import Services from '../../../components/services/Services'
import mockup5 from "../../../assets/mockup5.png"
import mockup6 from "../../../assets/mockup6.png"
import ThemeButton from '../../../components/reuseable/ThemeButton'
import { useNavigate } from 'react-router-dom'
import marketbannermock from "../../../assets/marketbannermock.png"

const Marketing = () => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/form")
  }
  const para = (
    <>
      Your book's success inevitably depends on the number of people it reaches – and what better way to propel it from obscurity to bestseller status than with our expert book marketing services. <br /><br />
      We have an array of proven <b>EFFECTIVE STRATEGIES</b> up our sleeve which are sure to <b>SELL MORE</b> and more copies of your book. <br /><br />
      We believe your story deserves to be read, and our <b>MARKETING EXPERTS</b> will leave no stone unturned to <b>ENSURE</b> it reaches its <b> INTENDED AUDIENCE. </b> <br /><br />
    </>
  )
  const subHeading = (
    <>
      Your Book’s Sales are Directly Related to its Marketing!
    </>
  )
  return (
    <>
      <div id='banner'>
        <NavBar />
        <Banner paragraph={para} subHeading={subHeading} image={marketbannermock} />
      </div>
      <div>
        <Container className='overflow-hidden mb-5'>
          <Row className='justify-content-evenly'>
            <Col className="mt-5 pt-5" lg={6}>
              <div id='aboutContent' className='mt-5'>
                <h3 >Uno Publishing can help market your book.</h3>
                <p className='mt-5 mb-4'>Uno Publishing offers multiple marketing plans that help you create a push for your book across mutiple channels which include maintaining a social media campaign, creating author websites, running SEO services on those websites, Creating a PR campaign around your book launch, Creating valuable assets to help promote your book and expert consultations to help you network for your book promtion.</p>
                <ThemeButton name="Start Project" />
              </div>
            </Col>
            <Col className='mt-5 text-center' lg={4} >
              <img src={mockup5} alt="" style={{ width: "110%" }} />
            </Col>
          </Row>
          <Row className='justify-content-evenly'>
            <Col className='mt-5  text-center' lg={6} >
              <img src={mockup6} alt="" style={{ width: "80%" }} />
            </Col>
            <Col className="mt-5 " lg={5}>
              <div id='aboutContent' className='mt-5 pt-5'>
                <h3 className='mt-3'>Effective Sales-Centric Digital Marketing for Self-published Authors.</h3>
                <p className='mt-5 mb-4'>From Search Engine Optimization (SEO) to Social Media Marketing, we’ve got you covered. At Uno Publishing, our marketing team makes sure your published book gains maximum interest from its target audience. Our expert digital marketers use their expertise to promote your book and reach potential customers. Uno Publishing are your one-stop source for all your book marketing solutions.</p>
                <ThemeButton name="Start Project" click={handleClick} />
              </div>
            </Col>
          </Row>

        </Container>
      </div>
      <div>
        <Services />
        <Portfolio />
        <Contact />
        <Faq />
        <Footer />
      </div>
    </>
  )
}

export default Marketing