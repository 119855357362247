import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import ThemeButton from '../../../components/reuseable/ThemeButton'
import Banner from '../../../components/banner/Banner'
import Contact from '../../../components/contact/Contact'
import Faq from '../../../components/faq/Faq'
import Footer from '../../../components/footer/Footer'
import NavBar from '../../../components/Navbar'
import Portfolio from '../../../components/portfolio/Portfolio'
import Services from '../../../components/services/Services'
import audile from '../../../assets/audile.png'
import { useNavigate } from 'react-router-dom'
import EleNor from '../../../assets/EleNor.png'

const AudiobookNarration = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/form")
  }
  const para = (
    <>
      If you're considering transforming your book or e-book into a professionally produced retail-ready audiobook, we have the perfect solution. <br /><br />
      Get in touch to have <b>YOUR VERY OWN AUDIOBOOK TODAY!</b>
    </>
  )
  const subHeading = (
    <>
      Broaden Your Audience with a Captivating Digital Audiobook!
    </>
  )
  return (
    <>
      <div id='banner'>
        <NavBar />
        <Banner subHeading={subHeading} paragraph={para} image={EleNor} />
      </div>
      <Container className='overflow-hidden mb-5'>
        <Row className='justify-content-evenly'>
          <Col className='mt-5 pt-5 ps-2' lg={6} >
            <img src={audile} className='mt-5' alt="" style={{ width: "90%" }} />
          </Col>
          <Col className="mt-5 pt-5" lg={6}>
            <div id='aboutContent' className='mt-5'>
              <h3>Why Should Your Book Also be an Audiobook? </h3>
              <p className='mt-5 mb-4'>Back in December 2004, the Audio Publishers Association estimated the audiobook market to be valued at $800 million. Since then, the sales growth of audiobooks has skyrocketed, thanks to the convenience of downloading from platforms like Audible.com, iTunes, and ChristianAudio.com. Do you see the potential?
              </p>

              <ThemeButton name="Start Project" click={handleClick} />
            </div>
          </Col>
        </Row>
      </Container>
      <div>
        <Services />
        <Portfolio />
        <Contact />
        <Faq />
        <Footer />
      </div>
    </>
  )
}

export default AudiobookNarration