import React from 'react'
import Banner from '../../../components/banner/Banner'
import Contact from '../../../components/contact/Contact'
import Faq from '../../../components/faq/Faq'
import Footer from '../../../components/footer/Footer'
import NavBar from '../../../components/Navbar'
import Portfolio from '../../../components/portfolio/Portfolio'
import Services from '../../../components/services/Services'
import mockup4 from "../../../assets/mockup4.png"
import { Col, Container, Row } from 'react-bootstrap'
import ThemeButton from '../../../components/reuseable/ThemeButton'
import publishbannermoc from "../../../assets/publishbannermoc.png"
const Publishing = () => {
  const para = (
    <>
        At Uno Publishing, we believe in empowering writers at every stage of their journey, whether you're just starting out or an experienced wordsmith. <br /><br />

        Our comprehensive suite of services is designed to meet all your publishing needs under one roof: From crafting your manuscript to editing, proofreading, formatting, packaging, marketing, printing, and publishing, we've got you all covered.  <br /><br />

        We're here to bring your vision to life, ensuring your book reflects your unique voice and ideas. Best of all, we take zero credit and zero royalties— since it's your name, your book, and your story.  <br /><br />
    </>
);
const subHeading = (
    <>
        Ready to Become a Published Author?
    </>
)
  return (
    <>
      <div id='banner'>
        <NavBar />
        <Banner paragraph={para} subHeading={subHeading} image={publishbannermoc} />
      </div>
      <div>
        <Container className='overflow-hidden mb-5'>
          <Row className='justify-content-evenly '>
            <Col className='mt-5  ps-2 text-center' lg={5} >
              <img src={mockup4} alt="" style={{ width: "100%" }} />
            </Col>
            <Col lg={5}>
              <div id='aboutContent' className='mt-5 pt-5'>
                <h3 >The Most Efficient Publishing Process.</h3>
                <p className='mt-5 mb-4'>At Uno Publishing, we have a team of experienced writers and publishers who have made their mark in the industry. Our process is simple, systematic, and fast. With the publishing team’s excellence and expertise, Uno Publishing have successfully assisted hundreds of aspiring writers worldwide to achieve their dream of becoming published authors with award-winning credentials.</p>
                <ThemeButton name="Start Project" />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Services />
        <Portfolio />
        <Contact />
        <Faq />
        <Footer />
      </div>
    </>
  )
}

export default Publishing