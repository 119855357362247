import { useState } from 'react'
import"./style.css";
import { Col, Row } from 'react-bootstrap'
import { Slider } from 'antd';
import ImageSlider from '../ImageSlider/ImageSlider'
import TestiMonials from "../../components/TestiMonials/TestiMonials"

const Portfolio = () => {
  return (
    <div>
      <section className='overflow-hidden'>
        <div className='pt-5 text-center container w-75' id='portfolioHeading'>
          <h2 className='text-center'>Publishing Each Book with Perfection and Professionalism</h2>
          <p>We're a collaborative team focused on perfection, trust, and creating magic. Check out our portfolio to see the magic we’ve helped many authors create around the globe!</p>
        </div>
        <div>
          <TestiMonials />
        </div>
      </section>

    </div>
  )
}

export default Portfolio

