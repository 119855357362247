import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Banner from '../../../components/banner/Banner'
import Contact from '../../../components/contact/Contact'
import Faq from '../../../components/faq/Faq'
import Footer from '../../../components/footer/Footer'
import NavBar from '../../../components/Navbar'
import Portfolio from '../../../components/portfolio/Portfolio'
import Services from '../../../components/services/Services'
import mockup8 from "../../../assets/mockup8.png"
import ThemeButton from '../../../components/reuseable/ThemeButton'
import { useNavigate } from 'react-router-dom'
import CAIO from "../../../assets/CAIO.png"

const WritingPublishing = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/form")
  }
  const para = (
    <>
      We specialize in nonfiction, business books, memoirs, self-help books, and how-to guides. Whatever genre you dream of writing, we possess the expertise to transform your dream into a reality.
    </>
  )
  const subHeading = (
    <>
      Transform Your Book Idea into Polished Prose
    </>
  )
  return (
    <>
      <div id='banner'>
        <NavBar />
        <Banner subHeading={subHeading} paragraph={para} image={CAIO} />
      </div>
      <Container className='overflow-hidden mb-5'>
        <Row className='justify-content-evenly'>
          <Col className="mt-5 pt-5" lg={7}>
            <div id='aboutContent' className='mt-5'>
              <h3 >Unparalleled Editing And Proofreading Services, Only At Uno Publishing.</h3>
              <p className='mt-5 mb-4'>Whether you are an internationally acclaimed author or just starting, sometimes you need an expert to go through your best work. At Uno Publishing, our team of expert editors ensures that your work is error-free and matches the standards of publishing. Let the most versatile experts at Uno Publishing analyze your masterpiece and make it flawless.</p>
              <ThemeButton name="Start Project" click={handleClick} />
            </div>
          </Col>
          <Col className='mt-2' lg={5} >
            <div className="text-center">
              <img src={mockup8} alt="" style={{ width: "100%" }} />
            </div>
          </Col>
        </Row>
      </Container>


      <div>
        <Services />
        <Portfolio />
        <Contact />
        <Faq />
        <Footer />
      </div>
    </>
  )
}

export default WritingPublishing