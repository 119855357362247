import "./style.css"
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import mockup2 from "../../assets/mockup2.png"
import ThemeButton from "../reuseable/ThemeButton"
import ThemeButtonLight from "../reuseable/ThemeButtonLight"
import { ZendeskAPI } from "../../ZendeskConfig"
import { useNavigate } from "react-router-dom"

const Leading = () => {
    const submit6 = () => {
        ZendeskAPI("messenger", "open");
    };
    const navigate = useNavigate();
    const handleClick = () => {
        navigate("/form")
    }
    return (
        <>
            <div id="leading">
                <section className='overflow-hidden mt-5'>
                    <div className="text-center mt-5 px-1 container w-75" id='servicesBoxTwo'>
                        <h2 className="text-center" style={{ color: "#E6A804" }}>Industry-Leading Book cover Design and Illustrations</h2>
                        <p style={{ fontWeight: "400" }}>At Uno, we understand the significance of eye-catching book covers and engaging illustrations. Our creative experts excel at breathing life into your book through captivating visuals that enhance your storytelling.</p>
                    </div>
                    <Container className='overflow-hidden w-75' id="leadingContainer">
                        <Row className='justify-content-evenly'>
                            <Col className="mt-5 " lg={6}>
                                <div id='aboutContent' className='mt-5'>
                                    <h3 style={{ color: "#f8b830" }}>Book Illustration:</h3>
                                    <p className='mt-1 mb-4 '>Illustrations breathe life into stories, captivating readers and enriching their experience. Our talented illustrators bring your narrative to vibrant reality, complementing your words with captivating visuals. With each stroke, they create enchanting scenes that transport readers into your book's world, leaving a lasting impression on their imagination.</p>
                                    <h3 style={{ color: "#f8b830" }}>Book Covers:</h3>
                                    <p className='mt-1 mb-4 '>A book cover speaks volumes with its visuals—it has the power to captivate and leave a lasting impression on potential readers. While you express your story through words, our designers craft a unique visual narrative that accurately portrays your book. From the very first glance, your cover will intrigue and entice readers, inviting them to delve deeper into your story.</p>
                                    <div className="">
                                        <ThemeButton name="Start Project" click={handleClick} />
                                    </div>
                                </div>
                            </Col>
                            <Col className='mt-5 text-center' lg={6} >
                                <img src={mockup2} alt="" style={{ width: "80%" }} id='aboutImg' />
                            </Col>
                        </Row>
                    </Container>

                </section>
            </div>
        </>
    )
}

export default Leading