import { Col, Container, Row } from "react-bootstrap";
import "./style.css"
import Accordion from 'react-bootstrap/Accordion';
import partner1 from "../../assets/partner1.png"
import partner3 from "../../assets/partner3.png"
import partner4 from "../../assets/partner4.png"

const Faq = () => {
    return (
        <>
            <section>
                <div className="text-center px-1 mt-5">
                    <h2 className="text-center" style={{ color: "#E6A804" }}>Frequently Asked Question</h2>
                    <p style={{ fontWeight: "400" }}>In this section, we tackle common FAQs on self-publishing your book successfully.</p>
                </div>

                <div className='container w-50 my-5' id="faqQuestAns">
                    <Accordion defaultActiveKey="" id='faq' style={{ border: "none !important" }}>
                        <Accordion.Item className="mt-1" eventKey="0">
                            <Accordion.Header>How can Uno Publishing help me publish my book?</Accordion.Header>
                            <Accordion.Body>
                            Uno Publishing Solutions is an assistance provider to online publishing services for Uno Publishing Solutions and many other platforms. With Uno Publishing Solutions you can get your book published and promoted with the help of expert consultants and apt marketing solutions.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="1">
                            <Accordion.Header>What do I need to publish my book?</Accordion.Header>
                            <Accordion.Body>
                            The first thing you need is an idea for a book. We can take care of everything else. Whether it's ghostwriting, editng or publishing, Uno Publishing Solutions can help you get through the hurdles of getting your book published and live for sales.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="2">
                            <Accordion.Header>Can Uno Publishing write my book for me?</Accordion.Header>
                            <Accordion.Body>
                            Uno Publishing Solutions has extensive teams of diverse writers and editors who can write your book for you or help you write your book yourself. No matter what the subject of your choice is, we can accomodate a wide range of writers for your book.                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="3">
                            <Accordion.Header>How can Uno Publishing help sell my book?</Accordion.Header>
                            <Accordion.Body>
                            Uno Publishing Solutions gives multiple book marketing services to help you build a credible profile across many channels and platforms to promote your book by taking it to your relevant target audience. With a 360 strategy and innovative approach, Uno Publishing Solutions can uplift your book sales and make you a prominent author online.                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item className="mt-1" eventKey="4">
                            <Accordion.Header>How do i get started?</Accordion.Header>
                            <Accordion.Body>
                            You can go on the live chat to get in touch with a customer representative, call and make an appointment with a consultant or leave us a message.                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </section>
            {/* <div >
                <Container className="text-center">
                    <Row>
                        <Col>
                        <div>
                            <img src={partner1} className="mb-3 partn" width={150} alt="" />
                        </div>
                        </Col>
                        <Col>
                        <div>
                            <img src={partner3} className="mb-3 partn" width={150} alt="" />
                        </div>
                        </Col>
                        <Col>
                        <div>
                            <img src={partner4} className="mb-3 partn" width={150} alt="" />
                        </div>
                        </Col>
                    </Row>
                </Container>
            </div> */}
        </>
    )
}

export default Faq