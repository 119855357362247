import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Banner from '../../../components/banner/Banner'
import Contact from '../../../components/contact/Contact'
import Faq from '../../../components/faq/Faq'
import Footer from '../../../components/footer/Footer'
import NavBar from '../../../components/Navbar'
import Portfolio from '../../../components/portfolio/Portfolio'
import Services from '../../../components/services/Services'
import mockup7 from "../../../assets/mockup7.png"
import ThemeButton from '../../../components/reuseable/ThemeButton'
import { useNavigate } from 'react-router-dom'
import professional from "../../../assets/professional.png"

const EditingProofreading = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/form")
  }
  const para = (
    <>
      Success-bound authors understand the importance of continuous refinement, and our professional editors ensure that the book you publish offers readers the best possible experience. <br /><br />
      So take your book from its basic form to a potential bestseller with <b>PROFESSIONAL EDITING</b> by Uno.
    </>
  )
  const subHeading = (
    <>
      Professional Book Editing Services
    </>
  )
  return (
    <>
      <div id='banner'>
        <NavBar />
        <Banner paragraph={para} subHeading={subHeading} image={professional} />
      </div>
      <div>
        <Container className='overflow-hidden mb-5'>
          <Row className='justify-content-evenly'>
            <Col className='mt-5 pt-5 ' lg={5} >
              <div className='ps-5 text-center'>
                <img src={mockup7} alt="" style={{ width: "75%" }} />
              </div>
            </Col>
            <Col className="mt-5 pt-5" lg={7}>
              <div id='aboutContent' className='mt-5'>
                <h3 >Unparalleled Editing And Proofreading Services, Only At Uno Publishing.</h3>
                <p className='mt-5 mb-4'>Whether you are an internationally acclaimed author or just starting, sometimes you need an expert to go through your best work. At Uno Publishing, our team of expert editors ensures that your work is error-free and matches the standards of publishing. Let the most versatile experts at Uno Publishing analyze your masterpiece and make it flawless.</p>
                <ThemeButton name="Start Project" click={handleClick} />
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div>
        <Services />
        <Portfolio />
        <Contact />
        <Faq />
        <Footer />
      </div>
    </>
  )
}

export default EditingProofreading