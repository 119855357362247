import { useState } from 'react';
import React from 'react'
import "./style.css";
import { Col, Row } from 'react-bootstrap';
import testimonial1 from "../../assets/testimonial1.png"
import testimonial2 from "../../assets/testimonial2.png"
import testimonial3 from "../../assets/testimonial3.png"
import testimonial4 from "../../assets/testimonial4.png"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ThemeButton from "../../components/reuseable/ThemeButton"
import ThemeButtonLight from "../../components/reuseable/ThemeButtonLight"
import { ZendeskAPI } from '../../ZendeskConfig';
import { useNavigate } from 'react-router-dom';


const Services = () => {
  const [size, setSize] = useState('small');

  const onChange = (e) => {
    setSize(e.target.value);
  };
  const submit5 = () => {
    ZendeskAPI("messenger", "open");
  };

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/form")
  }
  return (
    <>
      <div id='services' className='pt-5 overflow-hidden text-light'>
        <div className="shadowDown">
          <div className='text-center container w-75' id='servicesBoxTwo'>
            <h2 className='text-center'>A Team of Experts who Simplify the Process</h2>
            <p className="pt-2">At Uno, our process is simple. When you reach out to us:</p>
          </div>
          <div className='container w-75' id='servicesBoxThree'>
            <Tabs
              defaultActiveKey="home"
              id="justify-tab-example"
              className="mb-3 mt-5"
              justify
            >
              <Tab eventKey="home" title="Uno Publishing">
                <div>
                  <Row className='container justify-content-evenly text-center'>
                    <Col lg={4} md={10} sm={12}>
                      {/* image */}
                      <div className='mt-5 me-5' id='servicesImg'>
                        <img src={testimonial1} alt="testimonial" id='testimonial1' className='mt-3' />
                      </div>
                    </Col>
                    <Col lg={6} md={10} sm={12}>
                      <p className='mt-5 pt-5' id='serviceText'>We assign a dedicated publishing consultant to you, who teams up with an editor to review your manuscript and ensure it meets the KDP guidelines and is error-free. If your manuscript is ready to go, we take your book forward for publishing and you can enjoy your author status and royalties!
</p>
                      <div className='mb-5' id='serviceBtnBox' >
                        <a href="#contactDiv">
                          <ThemeButton name="Start project" click={handleClick} />
                        </a>
                        <span className="mx-3" id='ServiceCallNowBtn'>
                          <a href="tel:(737) 910 3898">
                            <ThemeButtonLight name="Call Now" />
                          </a>
                        </span>
                        <span id='liveChat'>
                          <a href="javascript:void(Tawk_API.toggle())"><ThemeButton name="Live Chat" /></a>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Book Editing Service" title="Book Editing">
                <div className=''>
                  <Row className='container justify-content-evenly text-center'>
                    <Col lg={4} md={10} sm={12}>
                      {/* image */}
                      <div className=' mt-5  me-5' id='servicesImg'>
                        <img src={testimonial4} alt="testimonial" id='testimonial1' className='mt-3' />
                      </div>
                    </Col>
                    <Col lg={6} md={10} sm={12}>
                      <p className='mt-5 pt-5' id='serviceText' >If it isn't error-free, don’t worry! We won’t blindly send it for publishing. Our experts will format, edit, and proofread it to ensure your manuscript is error-free and ready to be published and printed. Once your book is perfect, we finalize a captivating book cover that attracts thousands of eyeballs.
</p>
                      <div className='mb-5' id='serviceBtnBox'>
                        <a href="#contactDiv">
                          <ThemeButton name="Start project" click={handleClick} />
                        </a>
                        <span className="mx-3" id='ServiceCallNowBtn'>
                          <a href="tel:(737) 910 3898">
                            <ThemeButtonLight name="Call Now" />
                          </a>
                        </span>
                        <span id='liveChat'>
                          <a href="javascript:void(Tawk_API.toggle())">  <ThemeButton name="Live Chat" id="lc" /></a>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Book Marketing Service" title="Book Marketing">
                <div className=''>
                  <Row className='container justify-content-evenly text-center'>
                    <Col lg={4} md={10} sm={12}>
                      {/* image */}
                      <div className=' mt-5  me-5' id='servicesImg'>
                        <img src={testimonial2} alt="testimonial" id='testimonial1' className='mt-3' />
                      </div>
                    </Col>
                    <Col lg={6} md={10} sm={12}>
                      <p className='mt-5 pt-5' id='serviceText'>You can avail our book marketing services to get the word out there. You can also choose our Amazon optimization service and get an author website made to improve your sales. Remember! If your book is written and designed well, nothing but lack of marketing can hinder its sales.</p>
                      <div className='mb-5' id='serviceBtnBox'>
                        <a href="#contactDiv">
                          <ThemeButton name="Start project" click={handleClick} />
                        </a>
                        <span className="mx-3" id='ServiceCallNowBtn'>
                          <a href="tel:(737) 209 7255">
                            <ThemeButtonLight name="Call Now" />
                          </a>
                        </span>
                        <span id='liveChat'>
                          <a href="javascript:void(Tawk_API.toggle())">  <ThemeButton name="Live Chat" id="lc" /></a>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Book Writing Service" title="Book Writing">
                <div className=''>
                  <Row className='container justify-content-evenly text-center'>
                    <Col lg={4} md={10} sm={12}>
                      {/* image */}
                      <div className=' mt-5  me-5' id='servicesImg'>
                        <img src={testimonial3} alt="testimonial" id='testimonial1' className='mt-3' />
                      </div>
                    </Col>
                    <Col lg={6} md={10} sm={12}>
                      <p className='mt-5 pt-5' id='serviceText'>If you have a great story but writing is not your forte, we will assign a passionate and experienced ghost-writer to you. All our writers are passionate about storytelling, and after thoroughly understanding your story, they’ll begin weaving it into readable paragraphs and chapters!</p>
                      <div className='mb-5' id='serviceBtnBox'>
                        <a href="#conta ctDiv">
                          <ThemeButton name="Start project" click={handleClick} />
                        </a>
                        <span className="mx-3" id='ServiceCallNowBtn'>
                          <a href="tel:(737) 910 3898">
                            <ThemeButtonLight name="Call Now" />
                          </a>
                        </span>
                        <span id='liveChat'>
                          <a href="javascript:void(Tawk_API.toggle())">  <ThemeButton name="Live Chat" id="lc" /></a>
                        </span>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Tab>
             
            </Tabs>
          </div>
        </div>
      </div>
    </>
  )
}

export default Services